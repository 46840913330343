<template>
  <v-container>
    <BaseScreenHeader title="Lifting Stats" width="90%" screenInfo="STS-003" />

    <BaseFreeTier />
    <v-card flat class=" px-0">
      <v-sheet color="lightHeader" class="pa-2" :elevation="3">
        <v-card-actions class="py-0">
          <v-spacer />
        </v-card-actions>
        <v-sheet shaped class="charcoal paper--text pa-2">
          <v-card-actions class="py-0">
            <v-switch
              color="accent "
              class="mr-2"
              title="Metric?"
              v-model="metric"
            >
              <template v-slot:label>
                <span class="silver--text">Metric?</span>
              </template>
            </v-switch>

            <v-spacer />
            <v-icon
              large
              class="paper--text"
              @click="showPowerliftingInfo = !showPowerliftingInfo"
            >
              mdi-information
            </v-icon>
          </v-card-actions>
          <v-card-actions class="py-0">
            <v-spacer />
            <span :class="textSize + ' progressActive--text pb-2'">
              Powerlifting Numbers
            </span>
            <v-spacer />
          </v-card-actions>
          <v-card-text
            v-if="showPowerliftingInfo"
            class="silver--text"
            v-html="tooltips['powerlifting#']"
          >
          </v-card-text>
        </v-sheet>
        <v-card-actions class="px-0 pb-0 mb-n5">
          <v-spacer />
          <v-select
            filled
            v-model="mode"
            color="success "
            item-color="success"
            return-object
            prepend-inner-icon="mdi-calendar"
            :items="modes"
            label="Mode"
            item-text="name"
            item-value="filter"
            :menu-props="{
              closeOnClick: true,
              maxHeight: 280,
              transition: 'fab-transition'
            }"
          />
        </v-card-actions>
      </v-sheet>

      <template v-if="showChart">
        <PowerliftingChart
          :mode="mode"
          :selection="groupby"
          :key="switchnum"
          :metric="metric"
        />
      </template>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const PowerliftingChart = () =>
  import(
    /* webpackPrefetch: true */ '@/components/charts/lifting/PowerliftingChart.vue'
  )
import tooltips from '@/json/tooltips.json'
export default {
  mixins: [util],
  name: 'WeightLiftingStats',
  components: {
    PowerliftingChart
  },
  data: () => ({
    tooltips: tooltips,
    showOptions: false,
    showPowerliftingInfo: false,
    showRows: true,
    topVolume: [],
    mode: {
      name: 'Powerlifting Absolute',
      filter: 'power_abs',
      icon: '',
      label: 'Powerlifting Absolute'
    },
    type: 'imperial',
    groupby: null,
    loaded: false,
    switchnum: 0,
    feet: 0,
    inches: 0,
    metric: false,
    maxVolume: '',
    showChart: true,
    strengthNumber: {},
    filterItem: {},
    modes: [
      {
        name: 'Powerlifting Absolute',
        filter: 'power_abs',
        icon: '',
        label: 'Powerlifting Absolute'
      },
      {
        name: 'Powerlifting Relative',
        filter: 'power_rel',
        icon: '',
        label: 'Powerlifting Relative'
      },
      {
        name: 'CFT Absolute',
        filter: 'cft_abs',
        icon: '',
        label: 'CFT Absolute'
      },
      {
        name: 'CFT Relative',
        filter: 'cft_rel',
        icon: '',
        label: 'CFT Relative'
      }
    ]
  }),
  computed: {
    ...appConfig,
    ...builder,
    getLabel() {
      let val = ''
      if (this.mode == 'bymuscle') val = 'Muscle'
      if (this.mode == 'byexercise') val = 'Exercise'
      if (this.mode == 'byworkout') val = 'Workout'
      if (this.mode == 'bymusclegroup') val = 'Muscle Group'
      if (this.mode == 'bybodyposition') val = 'Body Position'
      return val
    }
  },
  beforeMount() {
    this.loadStrengthNumber()
  },
  methods: {
    setMode(filter) {
      this.mode = filter
      if (filter == 'overall') this.resetMode()
      this.loadTopVolume(this.mode)
      this.showOptions = false
    },
    resetMode() {
      this.mode = 'overall'
      this.filterItem = {}
      this.topVolume = []
    },
    saveGroupBy(value) {
      this.groupby = value
    },
    switchChart(item) {
      this.filterItem = item
      this.showRows = !this.showRows
      this.switchnum++
    },
    loadTop(what) {
      this.resetMode()
      this.showRows = what != 'overall'
      this.loaded = false
      this.mode = what
      this.loadTopVolume(what)
    },
    loadTopVolume(what) {
      {
        return axios
          .get(this.baseURL + '/stats/lifting/topvolume/' + what, {})
          .then(response => {
            if (response.status == 200) {
              this.topVolume = response.data.data
              this.loaded = true
              this.switchnum++
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadStrengthNumber() {
      {
        return axios
          .get(this.baseURL + '/stats/strength/number', {})
          .then(response => {
            if (response.status == 200) {
              this.strengthNumber = response.data.data
              this.switchnum++
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>
