<template>
  <v-container>
    <BaseScreenHeader title="Lifting Stats" width="90%" screenInfo="STS-003" :showBack="true" :showNav="true"/>

    <BaseFreeTier />
    <v-card flat tile class="charcoal px-0">
      <v-sheet
        color="transparent"
        class="pa-2"
        :style="charcoalTile"
        :elevation="3"
      >
        <v-sheet shaped class="transparent paper--text py-2">
          <v-card-actions class="pa-0">
            <v-icon large class="charcoal--text">
              mdi-information
            </v-icon>
            <v-spacer />
            <span @click="$router.push('/mystrength')" class="link">
              STRENGTH NUMBER </span
            ><BaseLinkIcon />
            <v-spacer />
            <v-icon
              large
              class="paper--text"
              @click="showPowerStrengthNum = !showPowerStrengthNum"
            >
              mdi-information
            </v-icon>
          </v-card-actions>
          <v-card-actions class="py-0">
            <v-spacer />
            <span :class="textSizeLarge + ' progressActive--text'">
              {{ strengthNumber.strength_number }}%
            </span>
            <v-spacer />
          </v-card-actions>
          <v-card-text
            v-if="showPowerStrengthNum"
            class="silver--text pa-0"
            v-html="tooltips['strength#']"
          >
          </v-card-text>
        </v-sheet>
        <v-card-actions class="px-0 py-0">
          <v-list v-if="isPhone" class="transparent py-0 ">
            <v-subheader
              class="silver--text  body-1 pl-0"
              @click="showOptions = !showOptions"
            >
              <v-icon large color="silver">
                mdi-gesture-tap-box
              </v-icon>
              <span class="pl-2">VIEW VOLUME BY</span>
            </v-subheader>
            <v-list-item-group
              v-if="showOptions"
              v-model="mode"
              class="charcoal--text mt-n1"
            >
              <v-list-item
                v-for="(currentMode, index) in modes"
                :key="index"
                :value="currentMode.filter"
                class="charcoal paper--text "
              >
                <v-list-item-icon>
                  <v-icon>{{ currentMode.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="charcoal paper--text ">
                  <v-list-item-title @click="setMode(currentMode.filter)">
                    {{ currentMode.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <template v-else>
            <v-spacer />
            <v-btn
              v-for="(currentMode, index) in modes"
              :key="index"
              :title="'Volume History ' + currentMode.label"
              class="charcoal paper--text  pa-2 ma-2 title"
              text
              :outlined="mode == currentMode.filter"
              @click="loadTop(currentMode.filter)"
            >
              {{ currentMode.name }}
            </v-btn>
            <v-spacer />
          </template>
        </v-card-actions>
        <v-card-actions v-if="!isPhone" class="pa-0 silver--text">
          <v-spacer />
          <span>
            Click the buttons above to switch the chart.
          </span>
          <v-spacer />
        </v-card-actions>
        <v-card-actions v-if="!isPhone" class="body-1 px-0 silver--text ">
          <v-spacer />
          <v-switch v-model="metric" color="accent" label="Metric?" />
        </v-card-actions>
        <v-card-actions v-else class="caption pa-0 silver--text ">
          <span v-if="mode != 'overall'">
            Click table rows below for detailed charts.
          </span>
        </v-card-actions>
      </v-sheet>

      <v-simple-table
        v-if="mode != 'overall'"
        class="transparent"
        :style="charcoalTileMenu"
        flat
      >
        <thead>
          <tr>
            <th
              class="text-left title silver--text"
              @click="showRows = !showRows"
            >
              Select {{ getLabel }}
            </th>
            <template v-if="showRows" class="">
              <th :class="textSizeXSmall + ' silver--text'">Vol. (lbs/kg)</th>
            </template>
            <template v-else>
              <th class="text-right silver--text">
                <v-spacer />
                <v-icon x-large color="silver" @click="showRows = !showRows">
                  mdi-menu-down
                </v-icon>
              </th>
            </template>
          </tr>
        </thead>
        <tbody v-if="showRows" class="silver--text">
          <tr
            @click="switchChart(top)"
            v-for="top in topVolume"
            :key="top.label"
            style="cursor:pointer"
          >
            <td :class="textSizeXSmall">{{ top.label }}</td>
            <td :class="textSizeXSmall">
              {{ Math.floor(top.imperial / 1000) }} k /
              {{ Math.floor(top.metric / 1000) }} k
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <template v-if="showChart">
        <VolumeChart
          :mode="mode"
          :what="filterItem"
          :selection="groupby"
          :key="switchnum"
          :metric="metric"
          @group_by="saveGroupBy"
        />
      </template>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const VolumeChart = () =>
  import(
    /* webpackPrefetch: true */ '@/components/charts/lifting/VolumeChart.vue'
  )
import tooltips from '@/json/tooltips.json'
export default {
  mixins: [util],
  name: 'WeightLiftingStats',
  components: {
    VolumeChart
  },
  data: () => ({
    tooltips: tooltips,
    showOptions: false,
    showPowerStrengthNum: false,
    showRows: true,
    topVolume: [],
    mode: 'overall',
    type: 'imperial',
    groupby: null,
    loaded: false,
    switchnum: 0,
    feet: 0,
    inches: 0,
    metric: false,
    maxVolume: '',
    showChart: true,
    strengthNumber: {},
    filterItem: {},
    modes: [
      { name: 'Overall', filter: 'overall', icon: '', label: 'Overall' },
      { name: 'Muscle', filter: 'bymuscle', icon: '', label: 'By Muscle' },
      {
        name: 'Exercise',
        filter: 'byexercise',
        icon: '',
        label: 'By Exercise'
      },
      { name: 'Workout', filter: 'byworkout', icon: '', label: 'By Workout' },
      {
        name: 'Muscle Group',
        filter: 'bymusclegroup',
        icon: '',
        label: 'By Muscle Group'
      },
      {
        name: 'Body Position',
        filter: 'bybodyposition',
        icon: '',
        label: 'By Body Position'
      }
    ]
  }),
  computed: {
    ...appConfig,
    ...builder,
    getLabel() {
      let val = ''
      if (this.mode == 'bymuscle') val = 'Muscle'
      if (this.mode == 'byexercise') val = 'Exercise'
      if (this.mode == 'byworkout') val = 'Workout'
      if (this.mode == 'bymusclegroup') val = 'Muscle Group'
      if (this.mode == 'bybodyposition') val = 'Body Position'
      return val
    }
  },
  beforeMount() {
    this.loadStrengthNumber()
  },
  methods: {
    setMode(filter) {
      this.mode = filter
      if (filter == 'overall') this.resetMode()
      this.loadTopVolume(this.mode)
      this.showOptions = false
    },
    resetMode() {
      this.mode = 'overall'
      this.filterItem = {}
      this.topVolume = []
    },
    saveGroupBy(value) {
      this.groupby = value
    },
    switchChart(item) {
      this.filterItem = item
      this.showRows = !this.showRows
      this.switchnum++
    },
    loadTop(what) {
      this.resetMode()
      this.showRows = what != 'overall'
      this.loaded = false
      this.mode = what
      this.loadTopVolume(what)
    },
    loadTopVolume(what) {
      {
        return axios
          .get(this.baseURL + '/stats/lifting/topvolume/' + what, {})
          .then(response => {
            if (response.status == 200) {
              this.topVolume = response.data.data
              this.loaded = true
              this.switchnum++
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadStrengthNumber() {
      {
        return axios
          .get(this.baseURL + '/stats/strength/number', {})
          .then(response => {
            if (response.status == 200) {
              this.strengthNumber = response.data.data
              this.switchnum++
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>
