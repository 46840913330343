<template>
  <v-container>
    <BaseScreenHeader
      title="My Stats"
      width="90%"
      screenInfo="STS-001"
      :showBack="true"
    />
    <template v-if="!basic">
      <WeightPrompt :show="!basic" @done="done" />
    </template>
    <v-card v-else flat class="transparent my-5 px-0">
      <BaseFreeTier v-if="loaded" />
      <v-row dense>
        <v-col cols="12" md="6">
          <v-sheet
            min-height="230"
            color="charcoal paper--text charcoalTile"
            class="px-2"
            :elevation="3"
          >
            <v-card-actions
              :class="textSize + '  paper--text pa-0 pb-4 link'"
              @click.self="showAddMeasurement = true"
            >
              Body Composition
              <v-spacer />
              <v-switch dark v-model="metric" color="accent" label="Metric?" />
            </v-card-actions>
            <template v-if="loaded">
              <v-card-actions
                :class="textSizeLarge + ' pa-0 success--text link mt-n4'"
              >
                <v-btn
                  title="Weight History"
                  class=" success--text pl-2 headline"
                  :style="charcoalTile"
                  ripple
                  large
                  plain
                  @click="basicMode = 'weight'"
                >
                  {{ oneDigit(getWeight) }} {{ weightUnit(metric) }}
                </v-btn>
                <v-spacer />

                <template v-if="!metric">
                  <span>{{ feet }}' {{ inches }}"</span>
                </template>
                <template v-else>{{ cm }} cm</template>
              </v-card-actions>
              <v-card-actions class="px-0">
                <v-btn
                  title="BMI History"
                  :style="charcoalTile"
                  ripple
                  large
                  plain
                  class="orange--text pa-2 headline"
                  @click="basicMode = 'BMI'"
                >
                  BMI {{ oneDigit(basic.bmi) }}
                </v-btn>
                <v-btn
                  title="BFP History"
                  :style="charcoalTile"
                  ripple
                  large
                  plain
                  class="purpleAccent--text pa-2 headline"
                  @click="basicMode = 'BFP'"
                >
                  BFP {{ oneDigit(basic.bfp) }}
                </v-btn>
              </v-card-actions>
            </template>
            <v-progress-linear
              v-else
              color="progressActive"
              indeterminate
              rounded
              height="5"
            />

            <v-card-actions class="caption pa-0 silver--text ">
              Click above to load the corresponding chart.
              <br />
              Click the double arrow for more details.
              <v-spacer />
              <v-icon
                title="More Details"
                @click="goBasicDetails"
                class="paper--text  mr-n1 ml-1 text-h3 rounded"
              >
                mdi-chevron-double-right
              </v-icon>
            </v-card-actions>
          </v-sheet>
          <WeightChart v-if="basicMode == 'weight'" :metric="metric" />
          <BMIChart v-if="basicMode == 'BMI'" />
          <BFPChart v-if="basicMode == 'BFP'" />
        </v-col>
        <v-col cols="12" md="6">
          <v-sheet
            color="charcoal paper--text charcoalTile"
            min-height="230"
            class="px-3"
            :elevation="3"
          >
            <v-card-actions :class="textSize + ' paper--text pa-0 link'">
              Lifting Volume
              <v-spacer />
              <v-switch dark color="accent" v-model="metric" label="Metric?" />
            </v-card-actions>
            <v-card-actions class="px-0 mt-n3">
              <span class="accent--text ">
                <v-btn
                  title="More Lifting Details"
                  class=" success--text pl-2 headline"
                  :style="charcoalTile"
                  ripple
                  large
                  plain
                  @click="goLiftingDetails"
                >
                  {{ oneDigit(inBW) }} selfs
                </v-btn>
              </span>
            </v-card-actions>
            <v-card-actions class="px-0 pt-0 ">
              <span
                title="Maximum Volume"
                :class="textSize + '  success--text'"
              >
                Max Volume: {{ Math.floor(maxVolume * 10) / 10 }}k
                {{ weightUnit(metric) }}
              </span>
            </v-card-actions>
            <v-card-actions class="caption mt-3 silver--text pa-0">
              Click the double arrow or the button above for a more detailed
              breakdown.
              <v-spacer />
              <v-icon
                title="More Details"
                @click="goLiftingDetails"
                class="paper--text  mr-n1 ml-1 text-h3 rounded"
                >mdi-chevron-double-right</v-icon
              >
            </v-card-actions>
          </v-sheet>
          <VolumeChart
            @get_max="setMaxVolume"
            mode="overall"
            :what="what"
            :key="switchnum"
            :metric="metric"
          />
        </v-col>
      </v-row>
    </v-card>
    <AddMeasurement
      :show="showAddMeasurement"
      @done="doneAdding"
      @cancel="showAddMeasurement = false"
    />
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const WeightChart = () =>
  import('@/components/charts/measurements/WeightChart.vue')
const WeightPrompt = () => import('@/components/dialogs/WeightPrompt.vue')
const BMIChart = () =>
  import(
    /* webpackPrefetch: true */ '@/components/charts/measurements/BMIChart.vue'
  )
const BFPChart = () =>
  import(
    /* webpackPrefetch: true */ '@/components/charts/measurements/BFPChart.vue'
  )
const VolumeChart = () =>
  import(
    /* webpackPrefetch: true */ '@/components/charts/lifting/VolumeChart.vue'
  )

const AddMeasurement = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/AddMeasurement.vue')

export default {
  mixins: [util],
  name: 'MyStats',
  components: {
    WeightChart,
    BMIChart,
    BFPChart,
    VolumeChart,
    WeightPrompt,
    AddMeasurement
  },
  data: () => ({
    basic: {},
    what: { label: 'Overall' },
    basicMode: 'weight',
    type: 'imperial',
    hasWeight: false,
    loaded: false,
    showAddMeasurement: false,
    switchnum: 0,
    feet: 0,
    inches: 0,
    cm: 0,
    metric: true,
    metricWeight: 0,
    imperialWeight: 0,
    maxVolume: '',
    measurementid: 1
  }),
  beforeMount() {
    this.loadBasic()
    this.metric = this.useMetric
  },
  validations: {},
  watch: {
    metric(val) {
      if (val) this.type = 'metric'
      else this.type = 'imperial'
      this.loadBasic()
    }
  },
  computed: {
    inBW() {
      let myWeight = this.metric ? this.metricWeight : this.imperialWeight
      return Math.floor(((this.maxVolume * 1000) / myWeight) * 0.1) / 0.1
    },
    getWeight() {
      if (this.metric) return this.metricWeight
      else return this.imperialWeight
    },
    ...appConfig,
    ...builder
  },
  methods: {
    doneAdding() {
      this.showAddMeasurement = false
      this.done()
    },
    done() {
      this.loadBasic()
      this.switchnum++
    },
    calculateImperial() {
      this.metricWeight = this.basic.weight
      this.imperialWeight = this.twoDigits(
        this.basic.weight * (1 / this.factor)
      )
    },
    calculateMetric() {
      this.imperialWeight = this.basic.weight
      this.metricWeight = this.twoDigits(this.basic.weight * this.factor)
    },
    setMaxVolume(value) {
      this.maxVolume = value
    },
    goBasicDetails() {
      this.$router.push('/mystats/bodycomposition')
    },
    goLiftingDetails() {
      this.$router.push('/mystats/weightlifting')
    },
    loadBasic() {
      this.loaded = false
      return axios
        .get(this.baseURL + '/stats/basic/' + this.type, {})
        .then(response => {
          if (response.status == 200) {
            this.basic = response.data.data
            this.feet = Math.floor(this.basic.height / 12)
            this.inches = Math.floor(parseFloat(this.basic.height % 12))
            this.cm = this.oneDigit(this.basic.height)

            if (this.metric) this.calculateImperial()
            else this.calculateMetric()
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
