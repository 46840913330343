<template>
  <v-container>
    <BaseScreenHeader
      title="Body Composition"
      width="90%"
      screenInfo="STS-002"
      :showBack="true"
      :showNav="true"
    />
    <BaseFreeTier v-if="loaded" />
    <v-card rounded flat class="mb-0 mt-5 transparent px-0">
      <v-sheet v-if="loaded" class="transparent charcoalTile py-2">
        <v-card-actions :class="textSize + ' paper--text px-2 py-0'">
          Weight: {{ getWeight }} {{ weightUnit(metric) }}
          <v-spacer />
          <v-switch
            dark
            color="progressActive "
            class="mr-2 mt-n2"
            light
            title="Metric?"
            v-model="metric"
          >
            <template v-slot:label>
              <span class="silver--text">Metric?</span>
            </template>
          </v-switch>
        </v-card-actions>
        <v-sheet class="transparent mt-n4">
          <span class="link" @click="showAddMeasurement = true">
            <v-card-actions :class="textSize + ' silver--text py-0'">
              BMI: {{ basic.bmi }}
              <v-spacer />
              <span
                title="Your Height"
                :class="textSizeLarge + ' success--text  link'"
              >
                <span v-if="!metric"> {{ feet }}' {{ inches }}"</span>
                <span v-else>{{ cm }}</span>
              </span>
            </v-card-actions>
            <v-card-actions :class="textSize + ' success--text mt-n3 py-0'">
              BFP {{ basic.bfp }}
            </v-card-actions>
          </span>
          <v-list v-if="isPhone" class="transparent silver--text mx-2 py-0 ">
            <v-subheader
              class="paper--text  body-1 pl-0"
              @click="showOptions = !showOptions"
            >
              <v-icon large color="silver">
                mdi-gesture-tap-box
              </v-icon>
              <span class="pl-2">CHANGE CHART</span>
            </v-subheader>
            <v-list-item-group v-if="showOptions" class=" silver--text mt-n1">
              <v-list-item
                v-for="(currentMode, index) in modes"
                :key="index"
                :value="currentMode.filter"
                class="charcoal paper--text "
              >
                <v-list-item-icon>
                  <v-icon class="lightHeader paper--text">
                    {{ currentMode.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content class=" paper--text ">
                  <v-list-item-title @click="setMode(currentMode.filter)">
                    {{ currentMode.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <template v-else>
            <span class="ml-n1">
              <v-btn
                v-for="(currentMode, index) in modes"
                :key="index"
                :title="'Body Composition ' + currentMode.label"
                class="charcoal paper--text pa-2 ma-2 title"
                text
                :outlined="basicMode == currentMode.filter"
                @click="loadTop(currentMode.filter)"
              >
                {{ currentMode.name }}
              </v-btn>
            </span>
          </template>
        </v-sheet>
      </v-sheet>
      <v-progress-linear
        v-else
        color="progressActive"
        indeterminate
        rounded
        height="5"
      />
      <template v-if="showChart">
        <WeightChart v-if="basicMode == 'weight'" :metric="metric" />
        <BMIChart v-if="basicMode == 'BMI'" />
        <BFPChart v-if="basicMode == 'BFP'" />
        <WaistChart v-if="basicMode == 'waist'" :metric="metric" />
        <NeckChart v-if="basicMode == 'neck'" :metric="metric" />
        <HipChart v-if="basicMode == 'hips'" :metric="metric" />
      </template>
    </v-card>

    <AddMeasurement
      :show="showAddMeasurement"
      @done="done"
      @cancel="showAddMeasurement = false"
    />
  </v-container>
</template>

<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
const WeightChart = () =>
  import('@/components/charts/measurements/WeightChart.vue')
const BMIChart = () =>
  import(
    /* webpackPrefetch: true */ '@/components/charts/measurements/BMIChart.vue'
  )
const BFPChart = () =>
  import(
    /* webpackPrefetch: true */ '@/components/charts/measurements/BFPChart.vue'
  )
const WaistChart = () =>
  import('@/components/charts/measurements/WaistChart.vue')
const NeckChart = () =>
  import(
    /* webpackPrefetch: true */ '@/components/charts/measurements/NeckChart.vue'
  )
const HipChart = () =>
  import(
    /* webpackPrefetch: true */ '@/components/charts/measurements/HipChart.vue'
  )
const AddMeasurement = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/AddMeasurement.vue')
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  name: 'MyStats',
  components: {
    WeightChart,
    BMIChart,
    BFPChart,
    WaistChart,
    NeckChart,
    HipChart,
    AddMeasurement
  },
  data: () => ({
    showOptions: false,
    basic: {},
    metricWeight: 0,
    imperialWeight: 0,
    weightStats: {},
    BFPStats: {},
    BMIStats: {},
    waistStats: {},
    neckStats: {},
    hipStats: {},
    basicMode: 'weight',
    type: 'imperial',
    loaded: false,
    showAddMeasurement: false,
    switchnum: 0,
    feet: 0,
    inches: 0,
    cm: 0,
    metric: false,
    maxVolume: '',
    showChart: true,
    minBMI: 0,
    maxBMI: 0,
    minBFP: 0,
    maxBFP: 0,
    minWeight: 0,
    maxWeight: 0,
    modes: [
      { name: 'Weight', filter: 'weight', icon: '', label: 'Weight' },
      { name: 'BMI', filter: 'BMI', icon: '', label: 'BMI' },
      { name: 'BFP', filter: 'BFP', icon: '', label: 'BFP' },
      { name: 'WAIST', filter: 'waist', icon: '', label: 'WAIST' },
      { name: 'NECK', filter: 'neck', icon: '', label: 'NECK' },
      { name: 'HIPS', filter: 'hips', icon: '', label: 'HIPS' }
    ]
  }),

  beforeMount() {
    this.reload()
    this.metric = this.useMetric
  },
  validations: {},
  computed: {
    ...appConfig,
    ...builder,
    lengthUnit() {
      return this.metric ? 'cm' : 'inches'
    },
    getWeight() {
      if (this.metric) return this.metricWeight
      else return this.imperialWeight
    },
    inBW() {
      return (
        Math.floor(((this.maxVolume * 1000) / this.basic.weight) * 100) / 100
      )
    }
  },
  watch: {
    metric(val) {
      if (val) this.type = 'metric'
      else this.type = 'imperial'
      this.loadBasic()
    }
  },
  methods: {
    done() {
      this.showAddMeasurement = false
      this.reload()
    },
    reload() {
      this.loadBasic()
      this.loadWeightStats()
      this.loadBMIStats()
      this.loadWaistStats()
      this.loadNeckStats()
      this.loadHipStats()
      this.loadBFPStats()
    },
    resetMode() {
      this.switchnum++
    },
    loadTop(what) {
      this.resetMode()
      this.loaded = false
      this.basicMode = what
    },
    setMode(filter) {
      this.basicMode = filter
      this.showOptions = false
    },
    setMaxVolume(value) {
      this.maxVolume = value
    },
    goBasicDetails() {
      this.$router.push('/mystats/bodycomposition')
    },
    goLiftingDetails() {
      this.$router.push('/')
    },
    calculateImperial() {
      this.metricWeight = this.basic.weight
      this.imperialWeight = this.twoDigits(
        this.basic.weight * (1 / this.factor)
      )
    },
    calculateMetric() {
      this.imperialWeight = this.basic.weight
      this.metricWeight = this.twoDigits(this.basic.weight * this.factor)
    },
    loadBasic() {
      this.loaded = false
      return axios
        .get(this.baseURL + '/stats/basic/' + this.type, {})
        .then(response => {
          if (response.status == 200) {
            this.basic = response.data.data
            this.feet = Math.floor(this.basic.height / 12)
            this.inches = Math.floor(parseFloat(this.basic.height % 12))
            this.cm = this.basic.height
            if (this.metric) this.calculateImperial()
            else this.calculateMetric()
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadWeightStats() {
      {
        return axios
          .get(this.baseURL + '/stats/analytics/weight/' + 'alltime', {})
          .then(response => {
            if (response.status == 200) {
              this.weightStats = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadBMIStats() {
      {
        return axios
          .get(this.baseURL + '/stats/analytics/bmi/' + 'alltime', {})
          .then(response => {
            if (response.status == 200) {
              this.BMIStats = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadWaistStats() {
      return axios
        .get(this.baseURL + '/stats/analytics/waist/' + 'alltime', {})
        .then(response => {
          if (response.status == 200) {
            this.waistStats = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadNeckStats() {
      return axios
        .get(this.baseURL + '/stats/analytics/neck/' + 'alltime', {})
        .then(response => {
          if (response.status == 200) {
            this.neckStats = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadHipStats() {
      return axios
        .get(this.baseURL + '/stats/analytics/hip/' + 'alltime', {})
        .then(response => {
          if (response.status == 200) {
            this.hipStats = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadBFPStats() {
      {
        return axios
          .get(this.baseURL + '/stats/analytics/bfp/' + 'alltime', {})
          .then(response => {
            if (response.status == 200) {
              this.BFPStats = response.data.data
              this.loaded = true
              this.switchnum++
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>
